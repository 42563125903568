import * as React from 'react'
import { Redirect } from 'react-router'

export interface RedirectControllerState {
    redirectPath: string | null
}

class Index<Props> extends React.Component<Props, RedirectControllerState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            redirectPath: null,
        }
    }

    public redirect = (redirectPath: string) => {
        this.setState({ redirectPath })
    }

    public renderContent(): React.ReactNode {
        return null
    }

    public render() {
        if (this.state.redirectPath !== null) {
            return <Redirect to={this.state.redirectPath} push />
        }
        return this.renderContent()
    }
}

export default Index
