import * as React from 'react'

const Loader: React.FunctionComponent = () => (
    <div className="loader-wrapper">
        <div className="loader" />
        <p className="loading-text">Loading</p>
    </div>
)

export default Loader
