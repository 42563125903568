// tslint:disable:max-line-length
import * as React from 'react'

const Logo: React.FunctionComponent = () => (
    <svg width={50} height={50} viewBox="-9 -6 485 485" className="logo">
        <g transform="translate(101.378 -719.138)" strokeWidth={20} strokeLinejoin="bevel">
            <g stroke="#1871BB" fill="none">
                <path d="M150.563 1092.033a78.104 78.104 0 0 1-17.717 1.588c-33.034-.829-63.287-22.726-75.885-56.9a95.961 95.961 0 0 1-5.911-33.22c-1.332-40.965 23.72-91.865 50.714-143.491 16.717-31.972 34.18-64.223 46.583-94.566-1.638 44.883 4.567 76.34 13.887 101.037 20.754 54.997 56.953 76.478 56.38 138.07 0 42.186-28.56 79.358-68.05 87.482z" />
                <path d="M133.211 1093.857c-10.168 8.453-22.265 14.515-35.61 17.26-39.49 8.125-78.774-15.09-93.602-55.312a95.961 95.961 0 0 1-5.911-33.22C-4.07 956.25 62.954 863.869 95.385 784.53c-1.116 30.569 1.407 54.91 6.074 75.128M160.87 862.1c13.962-26.591 27.76-53.12 38.066-78.33-5.286 144.83 71.097 149.855 70.266 239.106 0 42.186-28.56 79.359-68.05 87.483-24.23 4.985-48.384-1.829-66.857-17.13" />
            </g>
            <circle cx={133.622} cy={954.638} r={230.681} fill="none" stroke="#1871BB" strokeLinecap="round" />
        </g>
    </svg>
)

export default Logo
