import createBrowserHistory from 'history/createBrowserHistory'
import * as React from 'react'
import { Route, Router, Switch } from 'react-router'
import Footer from '../elements/Footer'
import Header from '../elements/Header'
import Loading from './Loading'

function createSuspence(Component: any) {
    return function(props: any) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Component {...props} />
            </React.Suspense>
        )
    }
}

const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ '../pages/Home'))
const IDidntExpectAnyOfThis = React.lazy(() =>
    import(/* webpackChunkName: "IDidntExpectAnyOfThis" */ '../pages/IDidntExpectAnyOfThis')
)
const NotThatDifferent = React.lazy(() =>
    import(/* webpackChunkName: "NotThatDifferent" */ '../pages/NotThatDifferent')
)
const Chamamentos = React.lazy(() => import(/* webpackChunkName: "Chamamentos" */ '../pages/Chamamentos'))

const Routes: React.FunctionComponent = () => (
    <Router history={createBrowserHistory()}>
        <>
            <Header />
            <Switch>
                <Route exact path="/" component={createSuspence(Home)} />
                <Route exact path="/i-didnt-expect-any-of-this" component={createSuspence(IDidntExpectAnyOfThis)} />
                <Route exact path="/not-that-different" component={createSuspence(NotThatDifferent)} />
                <Route exact path="/chamamentos" component={createSuspence(Chamamentos)} />
            </Switch>
            <Footer />
        </>
    </Router>
)

export default Routes
