import * as React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo'
import Index from '../RedirectController'
import * as style from './style.scss'

class Header extends Index<{}> {
    public renderContent() {
        return (
            <header className={style.header}>
                <div className={style.headerContent}>
                    <div className={style.logoWrapper} onClick={() => this.redirect('/')}>
                        <Logo />
                    </div>
                    <Link to="/">
                        <h1 className={style.title}>
                            <span className={style.first}>controlled&nbsp;flame</span>{' '}
                            <span className={style.second}>records</span>
                        </h1>
                    </Link>
                </div>
            </header>
        )
    }
}

export default Header
