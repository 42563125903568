import * as React from 'react'
import Loader from '../elements/Loader'

const Loading: React.FunctionComponent<any> = ({ error, pastDelay }) => {
    if (error) {
        if (/Loading chunk \d+ failed/.test(error.message)) {
            window.location.reload(true)
        }
        return <div>Error!</div>
    } else if (pastDelay) {
        return <Loader />
    } else {
        return null
    }
}

export default Loading
