import 'core-js/es6/map'
import 'core-js/es6/set'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import './index.scss'
import Routes from './routing/Routes'

if (location.protocol !== 'https:' && process.env.ENVIRONMENT === 'prod') {
    location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
}

ReactDOM.render(<Routes />, document.getElementById('content'))
